import React from 'react';
import { render, screen, fireEvent } from '@testing-library/react';
import { userEvent } from '@testing-library/user-event';
import { SelectFormField } from '../SelectFormField';

describe('SelectFormField Component', () => {
  const mockOptions = [
    {
      value: 'option1',
      label: 'Option 1',
      disabled: false,
      hidden: false,
      selected: false,
    },
    {
      value: 'option2',
      label: 'Option 2',
      disabled: false,
      hidden: false,
      selected: true,
    },
    {
      value: 'option3',
      label: 'Option 3',
      disabled: true,
      hidden: false,
      selected: false,
    },
  ];

  it('renders the label correctly', () => {
    render(
      <SelectFormField
        label="Test Label"
        name="testName"
        options={mockOptions}
      />,
    );
    expect(screen.getByLabelText('Test Label')).toBeInTheDocument();
  });

  it('renders all options correctly', () => {
    render(
      <SelectFormField
        label="Test Label"
        name="testName"
        options={mockOptions}
      />,
    );
    const options = screen.getAllByRole('option');
    expect(options).toHaveLength(mockOptions.length);
    expect(options[0]).toHaveTextContent('Option 1');
    expect(options[1]).toHaveTextContent('Option 2');
    expect(options[2]).toHaveTextContent('Option 3');
    expect(screen.getByText('Option 1')).toBeInTheDocument();
    expect(screen.getByText('Option 2')).toBeInTheDocument();
    expect(screen.getByText('Option 3')).toBeInTheDocument();
  });

  it('disables the select field when isReadOnly is true', () => {
    render(
      <SelectFormField
        label="Test Label"
        name="testName"
        options={mockOptions}
        isReadOnly
      />,
    );
    expect(screen.getByRole('combobox')).toBeDisabled();
  });

  it('marks the required attribute when required is true', () => {
    render(
      <SelectFormField
        label="Test Label"
        name="testName"
        options={mockOptions}
        required
      />,
    );
    expect(screen.getByRole('combobox')).toBeRequired();
  });

  it('handles option attributes correctly', () => {
    render(
      <SelectFormField
        label="Test Label"
        name="testName"
        options={mockOptions}
      />,
    );
    const options = screen.getAllByRole('option');
    expect(options[0]).not.toBeDisabled();
    expect(options[1].selected).toBeTruthy();
    expect(options[2]).toBeDisabled();
  });

  it('should allow user to select an option', () => {
    render(
      <SelectFormField
        label="Test Label"
        name="testName"
        options={mockOptions}
      />,
    );

    expect(
      screen.getByRole('option', { name: 'Option 1' }).selected,
    ).toBeFalsy();
    expect(
      screen.getByRole('option', { name: 'Option 2' }).selected,
    ).toBeTruthy();
    expect(
      screen.getByRole('option', { name: 'Option 3' }).selected,
    ).toBeFalsy();

    fireEvent.change(screen.getByRole('combobox'), {
      target: { value: 'option1' },
    });

    expect(
      screen.getByRole('option', { name: 'Option 1' }).selected,
    ).toBeTruthy();
    expect(
      screen.getByRole('option', { name: 'Option 2' }).selected,
    ).toBeFalsy();
    expect(
      screen.getByRole('option', { name: 'Option 3' }).selected,
    ).toBeFalsy();
  });

  it('should prevent selection of a disabled option', async () => {
    render(
      <SelectFormField
        label="Test Label"
        name="testName"
        options={mockOptions}
      />,
    );

    const combobox = screen.getByRole('combobox');

    expect(screen.getByRole('option', { name: 'Option 3' })).toBeDisabled();

    await userEvent.selectOptions(combobox, ['option3']);

    expect(
      screen.getByRole('option', { name: 'Option 1' }).selected,
    ).toBeFalsy();
    expect(
      screen.getByRole('option', { name: 'Option 2' }).selected,
    ).toBeTruthy();
    expect(
      screen.getByRole('option', { name: 'Option 3' }).selected,
    ).toBeFalsy();

    await userEvent.selectOptions(combobox, 'option1');

    expect(
      screen.getByRole('option', { name: 'Option 1' }).selected,
    ).toBeTruthy();
    expect(
      screen.getByRole('option', { name: 'Option 2' }).selected,
    ).toBeFalsy();
    expect(
      screen.getByRole('option', { name: 'Option 3' }).selected,
    ).toBeFalsy();
  });

  it('calls onChange when a new option is selected', () => {
    const handleChange = jest.fn();

    render(
      <SelectFormField
        label="Test Label"
        name="testName"
        options={mockOptions}
        onChange={handleChange}
      />,
    );

    fireEvent.change(screen.getByRole('combobox'), {
      target: { value: 'option1' },
    });

    fireEvent.change(screen.getByRole('combobox'), {
      target: { value: 'option2' },
    });

    expect(handleChange).toHaveBeenCalledTimes(2);
    expect(handleChange).toHaveBeenCalledWith('option1');
    expect(handleChange).toHaveBeenCalledWith('option2');
  });

  it('only calls onChange when a valid option is selected', async () => {
    const handleChange = jest.fn();

    render(
      <SelectFormField
        label="Test Label"
        name="testName"
        options={mockOptions}
        onChange={handleChange}
      />,
    );

    const combobox = screen.getByRole('combobox');

    expect(screen.getByRole('option', { name: 'Option 3' })).toBeDisabled();

    await userEvent.selectOptions(combobox, ['option3']);

    expect(
      screen.getByRole('option', { name: 'Option 1' }).selected,
    ).toBeFalsy();
    expect(
      screen.getByRole('option', { name: 'Option 2' }).selected,
    ).toBeTruthy();
    expect(
      screen.getByRole('option', { name: 'Option 3' }).selected,
    ).toBeFalsy();

    await userEvent.selectOptions(combobox, 'option1');

    expect(
      screen.getByRole('option', { name: 'Option 1' }).selected,
    ).toBeTruthy();
    expect(
      screen.getByRole('option', { name: 'Option 2' }).selected,
    ).toBeFalsy();
    expect(
      screen.getByRole('option', { name: 'Option 3' }).selected,
    ).toBeFalsy();

    expect(handleChange).toHaveBeenCalledTimes(1);
    expect(handleChange).toHaveBeenCalledWith('option1');
  });
});
